import { render, staticRenderFns } from "./EventDetails.vue?vue&type=template&id=284be13d&scoped=true"
import script from "./js/event-details.js?vue&type=script&lang=js&external"
export * from "./js/event-details.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "284be13d",
  null
  
)

export default component.exports